import * as React from "react";
import Layout from "../../components/layout";
import { Link } from "gatsby";
import BottomInquiry from "../../components/bottom-inquiry";
import CatPicker from "../../components/cat-picker";
import ProductCard from "../../components/product-card";
import { Tabs, Tab } from "react-bootstrap";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const soundModerators = ({ children }) => {
  return (
    <Layout>
      <section className="product-list">
        <div className="container main-layout">
          <div className="row">
            <h6 className="page-title">Weapon & Sound Moderators</h6>
            
            <div className="col-12">
              <CatPicker></CatPicker>
            </div>

            <div className="col-12">
              <Tabs defaultActiveKey="z-09" className="subcat">
                <Tab eventKey="z-09" title="Z-09">
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-4">
                      <Link to="/products/weapon/product-details">
                        <ProductCard></ProductCard>
                      </Link>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="z-10" title="Z-10">
                  asdasdas
                </Tab>
                <Tab eventKey="z-15" title="Z-15">
                  asdasdas
                </Tab>
                <Tab eventKey="z-0008" title="Z-0008">
                  asdasdas
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </section>
      <BottomInquiry></BottomInquiry>
    </Layout>
  )
}

export default soundModerators